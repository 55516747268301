import './App.css';
import {GeoJSON, MapContainer, Marker, Pane, Popup, TileLayer, Tooltip, useMap, useMapEvents} from 'react-leaflet'
import 'leaflet/dist/leaflet.css';
import L from "leaflet";
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';
import {useState} from "react";
import EXIF from "exif-js";
import '@ionic/react/css/core.css';
import { setupIonicReact } from '@ionic/react';
import { IonButton, IonDatetime } from '@ionic/react';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

setupIonicReact();

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconSize: [28, 46],
    iconAnchor: [17, 46],
});

const adminLevelStyles = {
    2: {
        radius: 8,
        color: "#000",
        opacity: 1,
        fillOpacity: 0.0,
    },
    3: {
        color: "#000",
        opacity: 1,
        fillOpacity: 0.1,
    },
    4: {
        color: "#000",
        opacity: 1,
        fillColor: "#00e1ff",
        fillOpacity: 0.2,
    },
    5: {
        color: "#000",
        opacity: 1,
        fillColor: "#00ff8c",
        fillOpacity: 0.3,
    },
    6: {
        fillColor: "#ff00dd",
        fillOpacity: 0.4,
    },
    7: {
        fillColor: "#ff0000",
        fillOpacity: 0.5,
    },
    8: {
        fillColor: "#ff4d00",
        fillOpacity: 0.6,
    },
    9: {
        fillColor: "#ffb700",
        fillOpacity: 0.7,
    },
    10: {
        fillColor: "#c8ff00",
        fillOpacity: 0.8,
    },
    11: {
        fillColor: "#0000ff",
        fillOpacity: 0.9,
    },
}

L.Marker.prototype.options.icon = DefaultIcon;

function App() {
    function LocationMarker() {
        const [position, setPosition] = useState(null)
        const map = useMapEvents({
            click() {
                map.locate()
            },
            locationfound(e) {
                setPosition(e.latlng)
                map.flyTo(e.latlng, map.getZoom())
            },
        })

        return position === null ? null : (
            <Marker position={position}>
                <Popup>You are here</Popup>
            </Marker>
        )
    }


    const [markers, setMarkers] = useState([])
    const [filesImported, setFilesImported] = useState(0)
    const [filesToImport, setFilesToImport] = useState(0)

    const LocationFinderDummy = () => {
        const [position, setPosition] = useState(null)
        const [areas, setAreas] = useState([])
        const [geoJsonBoundaries, setGeoJsonBoundaries] = useState([])

        const map = useMapEvents({
            click(e) {
                setAreas([])
                setGeoJsonBoundaries([])

                fetch('/api/geocode?lat=' + e.latlng.lat + '&lon=' + e.latlng.lng)
                    .then(response => response.json())
                    .then((data) => {
                        setAreas(data.areas)


                        data.areas.forEach((a) => {
                            fetch('/api/geo-json?id=' + a.id)
                                .then(response => response.json())
                                .then((data) => {

                                    setGeoJsonBoundaries(geoJsonBoundaries => [...geoJsonBoundaries, {
                                        area: data.area,
                                        info: a,
                                    }])
                                });
                        })
                    });
                setPosition(e.latlng)
            },
        });

        return <>
            {position &&
                <Marker position={position}>
                    <Popup>You are here:<br/>
                        {areas.map((a) => {
                            return <>
                                {a.admin_level} - {a.name_en}<br/>
                            </>
                        })}

                    </Popup>
                </Marker>
            }
            {geoJsonBoundaries.map((boundary) => (
                <Pane style={{zIndex: boundary.info.admin_level * 100}} name={boundary.info.name + " " + boundary.info.admin_level}>
                    <GeoJSON key={boundary.info.id} data={boundary.area} style={adminLevelStyles[boundary.info.admin_level]}>
                        <Tooltip sticky>{boundary.info.name} - {boundary.info.admin_level}</Tooltip>
                    </GeoJSON>
                </Pane>
            ))}
        </>
    };

    const upLoadFileFolderFunc = (e) => {

        function ConvertDMSToDD(degrees, minutes, seconds, direction) {
            var dd = degrees + (minutes / 60) + (seconds / 360000);
            if (direction == "S" || direction == "W") {
                dd = dd * -1;
            }
            return dd;
        }

        const readImage = async (file) => {
            return new Promise((resolve, reject) => {
                // Check if the file is an image.
                if (file.type && !file.type.startsWith('image/')) {
                    setFilesImported(filesImported => filesImported + 1)
                    console.log('File is not an image.', file.type, file);
                    return reject('File is not an image: ' + file.type);
                }

                EXIF.getData(file, function () {
                    setFilesImported(filesImported => filesImported + 1)
                    let exifData = this;
                    if (exifData.exifdata && exifData.exifdata.GPSLatitude) {
                        let latDegree = exifData.exifdata.GPSLatitude[0].numerator;
                        let latMinute = exifData.exifdata.GPSLatitude[1].numerator;
                        let latSecond = exifData.exifdata.GPSLatitude[2].numerator;
                        let latDirection = exifData.exifdata.GPSLatitudeRef;

                        let latFinal = ConvertDMSToDD(latDegree, latMinute, latSecond, latDirection);

                        let lonDegree = exifData.exifdata.GPSLongitude[0].numerator;
                        let lonMinute = exifData.exifdata.GPSLongitude[1].numerator;
                        let lonSecond = exifData.exifdata.GPSLongitude[2].numerator;
                        let lonDirection = exifData.exifdata.GPSLongitudeRef;

                        let lonFinal = ConvertDMSToDD(lonDegree, lonMinute, lonSecond, lonDirection);
                        console.log(latFinal, lonFinal)
                        setMarkers(markers => [...markers, {latitude: latFinal, longitude: lonFinal}])
                        return resolve({latitude: latFinal, longitude: lonFinal});
                    } else {
                        console.log("No EXIF data found in image '" + file.name + "'.");

                        return reject('No EXIF data found in image.');
                    }
                });
            })
        }


        e.preventDefault();
        let fileitemsList = e.target.files;
        setFilesToImport(fileitemsList.length)

        let promises = [];

        for (let i = 0; i < fileitemsList.length; i++) {
            let file = fileitemsList[i];
            promises.push(readImage(file))
        }

        Promise.all(promises)
            .catch((error) => {
                console.error('Error:', error);
            })
    }

    return (
        <>
            <span>{filesImported} / {filesToImport}</span><br/>
            <input type="file" webkitdirectory="" directory="" multiple="" onChange={upLoadFileFolderFunc}></input>
            <MapContainer center={[51.505, -0.09]} zoom={5} scrollWheelZoom={true}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <LocationFinderDummy/>
                <Marker position={[51.505, -0.09]}>
                    <Popup>
                        A pretty CSS3 popup. <br/> Easily customizable.
                    </Popup>
                </Marker>
                {markers.map((marker) => (
                    <Marker position={[marker.latitude, marker.longitude]}/>
                ))}
            </MapContainer>
        </>
    );
}

export default App;
